@import '_variables';
@import '_mixins';

//Headings

@heading-1-size: ceil(@font-size-base * 2.11);
@heading-2-size: ceil(@font-size-base * 1.74);
@heading-3-size: ceil(@font-size-base * 1.51);
@heading-4-size: ceil(@font-size-base * 1.42);
@heading-5-size: ceil(@font-size-base * 1.23);
@heading-6-size: ceil(@font-size-base * 1.12);

h1 {
  font-size: @heading-1-size;
}

h2 {
  font-size: @heading-2-size;
}

h3 {
  font-size: @heading-3-size;
}

h4 {
  font-size: @heading-4-size;
}

h5 {
  font-size: @heading-5-size;
}

h6 {
  font-size: @heading-6-size;
}

//Master Layout

.yjMasterLayoutWrapper {
  background: @color-bg-masterlayout-content-wrapper;

  .ant-form-item-label {


    label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {

      &::before {
        display: none;
      }

      &::after {
        color: #ff4d4f;
        content: '*';
        display: inline-block;
        font-size: 14px;
        line-height: 1;
        margin-left: 4px;
        margin-top: 4px;

      }
    }
  }
}

.yjMainContainer {
  background-color: @color-bg-main-wrapper;

  .ant-table-wrapper {
    position: relative;
    z-index: 1;
  }
}

.yjMainLayout {
  background: @color-bg-masterlayout;

  .ant-layout-has-sider {

    .ant-layout {
      margin-left: 70px;
    }
  }
}

.ant-table-cell-fix-right {
  background: #fff;
  z-index: 1;
}

//Ant Tree

.ant-tree {
  font-size: ceil(@font-size-base / 1.2);

  .ant-tree-treenode {
    width: 100%;

    .ant-tree-node-content-wrapper {
      border-bottom: 1px dotted lighten(@color-accent-border, 20%);
      width: 100%;
    }

    &.ant-tree-treenode-disabled {

      .ant-tree-node-content-wrapper {
        color: fade(@color-font, 80%);
      }
    }
  }
}

// Accordian Checkbox list (License Management - View , Edit)

.yjAccordianCheckboxList {

  .ant-tree-checkbox-checked {

    .ant-tree-checkbox-inner {
      background-color: @color-bg-checkbox-default;
      border-color: @color-border-checkbox-default;
      color: @color-font-checkbox-default;

      &::after {
        border-color: @color-border-checkbox-default;
      }
    }
  }

  .ant-tree-checkbox-disabled {

    .ant-tree-checkbox-inner {
      background-color: fade(@color-bg-checkbox-default, 40%);
      border-color: fade(@color-border-checkbox-default, 20%);

      &::after {
        border-color: fade(@color-border, 90%);
      }
    }
  }
}

//Common Checkbox (Grid column dropdown , )

.ant-checkbox-wrapper {

  .ant-checkbox-checked {

    .ant-checkbox-inner {
      background-color: @color-bg-checkbox-default;
      border-color: @color-border-checkbox-default;
      color: @color-font-checkbox-default;

      &::after {
        border-color: @color-border-checkbox-default;
      }
    }
  }

  .ant-checkbox-disabled {

    .ant-checkbox-inner {
      background-color: fade(@color-bg-checkbox-default, 40%);
      border-color: fade(@color-border-checkbox-default, 20%);

      &::after {
        border-color: fade(@color-border, 90%);
      }
    }
  }
}

//File Area Tree

.yjFileFinderTree {
  border-radius: 0;

  .ant-tree {
    background: transparent;

    .ant-tree-list {

      .ant-tree-treenode {
        padding: 0 0 2px 0;

        .ant-tree-node-content-wrapper {
          line-height: 22px;
          margin: 0 15px;
          min-height: 30px;
          padding: 5px 0;

          .ant-tree-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(70% - 30px);
          }

          .flex-display(inline-flex);
        }

        &:hover {

          .ant-tree-title {
            color: @color-secondary;
          }

          &::before {
            background: fade(@color-secondary, 20%);
          }
        }

        &.ant-tree-treenode-selected {

          .ant-tree-title {
            color: @color-white;

            .font-mixin(@font-primary, @yjff-semibold);
          }

          .ant-tree-iconEle {

            .anticon {
              color: @color-white;
            }
          }

          &::before {
            background: fade(@color-secondary, 50%);
          }
        }
      }

      .ant-tree-switcher {
        width: .5em;

        .anticon {

          &.ant-tree-switcher-icon {
            display: none;
          }
        }
      }

      .ant-tree-iconEle {

        .anticon {
          color: @color-secondary;
          margin-top: 3px;
        }
      }

      .ant-tree-title {
        color: @text-color;
        font-size: @font-size-base / 1.15;
        margin-left: .5em;
      }
    }

    .ant-tree-treenode::before {
      bottom: 0;
    }
  }
}

// form

.ant-form {

  label {
    font-size: 12px;
  }
}

//Ant Menu Popup

.ant-menu-submenu-popup {

  .ant-menu-item-selected,
  .ant-menu-item-active {
    background: @color-primary;
  }
}

//Ant Menu

.ant-menu.ant-menu-dark {
  background: inherit;

  .ant-menu-sub {
    background: @color-secondary;
  }

  .ant-menu-item {

    &:hover {
      background-color: fade(@color-primary, 80%);
    }
  }
}

.ant-menu-vertical {

  .ant-menu-item {
    margin: 0 auto;

    &:not (:last-child) {
      margin-bottom: 0;
    }
  }
}

.yjAccMenuItemList {

  li {

    label {

      span {
        font-size: @font-size-base / 1.2;
        opacity: 1;
      }
    }
  }
}

.ant-menu {

  &.ant-menu-inline-collapsed {
    margin: 0 -5px;
    width: 75px;
  }

  li {
    text-transform: @yj-transform;

    .anticon {
      font-size: @font-size-xlg;
    }

    span {
      font-size: .92em;
    }

    ul {

      li {
        font-size: .98em;
      }
    }
  }

  .ant-menu-submenu {

    .ant-menu-submenu-title {

      .anticon {
        font-size: @font-size-xlg;
      }
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
      background-color: lighten(@color-secondary, 30%);
    }
  }
}

/* Common styles for inputs with label - Added to keep the consistency of the inputs */

.ant-form-item {

  .ant-form-item-label {

    label {
      color: @color-font-form-label;
      font-size: @font-size-base / 1.145;
      height: 22px;
      text-transform: @yj-transform;

      .font-mixin(@font-primary, @yjff-semibold);
    }
  }
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

// Manage Tags

.ant-tag-blue {

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-control-input-content {

      .ant-input {
        padding: 4px;
      }
    }
  }
}

//Ant Button (Primary , Default , Disabled)

.ant-btn-primary {
  background: @color-bg-primary-button;
  font-size: ceil(@font-size-base / 1.2);
  margin-left: 5px;
  text-transform: @yj-transform;
}

.ant-btn-default {
  background: @color-bg-default-button;
  font-size: ceil(@font-size-base / 1.2);
  margin-left: 5px;
  text-transform: @yj-transform;
}

.ant-btn[disabled] {
  background: transparent;
}

//Ant Dropdown button

.ant-btn {

  &.ant-dropdown-trigger {
    background: transparent;
    border: 1px solid @color-white;
    border-radius: .2em;
    color: @color-white;
    font-size: ceil(@font-size-base / 1.2);
    margin-left: 5px;
    text-transform: @yj-transform;
  }
}

//Ant Input

.ant-form-item-control {

  .ant-form-item-explain {
    color: @color-danger;
    line-height: 2.5;
    min-height: 30px;

    div {
      font-size: @font-size-base / 1.3;
    }
  }
}

//Action Button List Grid

.yjActionIconWrapper {

  button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #24303B;
    margin-left: 0;

    &:hover {
      background-color: transparent;
      color: lighten(@color-primary, 20%);
    }

    &:focus {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }

    &:disabled {
      background-color: transparent;
    }
  }

  .yjPortalGridDeleteAction {
    color: @color-danger;
  }

  .flex-mixin(center, flex, space-between);
}

//Ant Modal

.ant-modal {

  .ant-modal-content {
    background: @color-bg-modal;

    .ant-modal-close-x {

      .ant-modal-close-icon {
        color: @color-close-icon-modal;
      }
    }

    .ant-modal-header {
      background: @color-bg-modal-header;
      border-bottom: 1px solid @border-color-base;

      .ant-modal-title {
        color: @color-font-modal-header;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
        width: 98%;
        .font-mixin(@font-primary, @yjff-semibold);
      }
    }

    .ant-modal-footer {

      button {
        font-size: 12px;

        &:last-child {
          background-color: @color-button-primary;
          border-color: @color-button-primary;
          color: @color-white;
          margin-right: 0;
        }

        .font-mixin(@font-primary, @yjff-semibold);
      }

      .ant-btn-primary[disabled] {
        background: @color-disabled-bg;
        border-color: @color-disabled-border;
        color: @text-color;
        text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
      }
    }
  }
}

//Ant Table

.ant-table {

  tbody {

    >tr {

      &.ant-table-row {

        &:hover {

          >td {
            background: @color-table-row-hover;

            &.yjActionColumn {
              background: @color-table-row-hover;
            }
          }
        }
      }
    }

    tr {

      &.table-row-dark {
        background-color: @color-table-row-highlight;

        td {
          background-color: @color-table-row-highlight;

          &.yjActionColumn {
            background-color: @color-table-row-highlight;
          }
        }
      }

      td {

        &.ant-table-cell {
          font-size: ceil(@font-size-base / 1.1);
          padding: 2px 16px;
        }
      }
    }
  }

  .ant-table-tbody {

    //td.ant-table-column-sort {
    //  background: transparent;
    //}

    tr.ant-table-row-selected {

      td {
        background: inherit;
      }
    }
  }
}

//Ant Select

.ant-select {
  width: 100%;
}

.ant-select-item {

  &.ant-select-item-option {
    font-size: @font-size-base / 1.1;

    .ant-select-item-option-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
    }
  }
}

//Channel Selector

.yjChannelSelector {
  border: 1px solid @color-primary;
  border-radius: .2em;
  margin: 0 .16em;
  width: 20vw;
}

//Pagination Selection

.ant-pagination-options {

  .ant-select {

    .ant-select-selector {
      font-size: ceil(@font-size-base / 1.2);
    }
  }
}

//Ant DateTime Picker

.ant-picker {
  cursor: default;

  .ant-picker-input {

    >input {
      font-size: @font-size-base / 1.1;
    }
  }

  .ant-picker-suffix {
    pointer-events: auto;

    .anticon {

      svg {
        cursor: default;
      }
    }
  }
}

//Progress Bar

.yjProgressBar {

  .ant-progress-outer {

    .ant-progress-inner {
      background-color: darken(@color-border, 10%);
      height: 5px;

      .ant-progress-bg {
        background-color: @color-bg-progress-bar;
      }
    }
  }
}

//Search-Popover

.ant-popover {

  .ant-list {

    .ant-list-item {
      cursor: pointer;
    }
  }
}

//stepper

.yjStepper {
  background-color: @color-bg-wizard-wrapper;
  border-radius: 3px;
  padding: 1em;

  .ant-steps {

    .ant-steps-item-active {

      .ant-steps-item-icon {
        background-color: @color-bg-wizard-icon-active;
        border-color: @color-border-wizard-icon-active;
      }
    }

    .ant-steps-item-wait {

      .ant-steps-item-icon {
        background-color: @color-bg-wizard-icon-wait;
        border-color: @color-border-wizard-icon-wait;
      }
    }

    .ant-steps-item-finish {

      .ant-steps-item-icon {
        background-color: @color-bg-wizard-icon-previous;
        border-color: @color-border-wizard-icon-previous;

        .ant-steps-icon {

          .ant-steps-finish-icon {

            svg {
              color: @color-font-verification-icon;
            }
          }
        }
      }

      .ant-steps-item-content {

        .ant-steps-item-title {

          &::after {
            background-color: @color-accent-secondary;
          }
        }
      }
    }
  }
}

// Drawer - Properties

.yjDrawerPanel {

  .ant-drawer-content-wrapper {
    width: 450px;

    .ant-drawer-header {
      background: @color-bg-drawer-header;

      .ant-drawer-title {
        color: @color-font-drawer-header-title;
        font-size: @font-size-lg;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: @yj-transform;
        white-space: nowrap;
        width: 90%;

        span {
          margin-right: 10px;
        }
      }

      .ant-drawer-close {
        color: @color-font-drawer-header-icon;
        cursor: pointer;
      }
    }

    .ant-drawer-body {
      background: @color-bg-drawer-body;
    }
  }
}

// Common Accordion

.yjCommonAccordian {
  border: 0;
  margin-bottom: 16px;

  .ant-collapse {
    border: none;

    .ant-collapse-item {
      box-shadow: none;
      margin-bottom: 10px;

      .ant-collapse-header {
        background: @color-bg-accordian-header;
        border-radius: 0;
        color: @color-font-accordian-header;
        font-size: @font-size-base / 1.2;
        text-transform: @yj-transform;

        .ant-collapse-arrow {

          svg {
            color: @color-bg-accordian-header-arrow;
          }
        }

        .yjAccordianLabel {
          display: flex;
          overflow: hidden;
          padding-top: 3px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 90%;
        }

        .ant-checkbox-disabled+span {
          color: @color-white;
        }

        .font-mixin(@font-primary, @yjff-semibold);
      }

      .ant-collapse-extra {
        padding-top: 3px;
      }

      &.ant-collapse-no-arrow {

        .ant-collapse-header {
          cursor: default;
          padding-left: 16px;
        }
      }

      .ant-collapse-content {
        border: 1px solid @color-border;
        border-bottom: 0;
        max-height: 352px;
        overflow-y: auto;

        .ant-collapse-content-box {
          font-size: @font-size-base / 1.2;

          .ant-list-empty-text {
            color: @color-primary;
          }
        }
      }
    }
  }
}

// Accordion Special Styles

.yjAccordianCheckbox {
  float: left;
  padding-right: 10px;
}

// Sub Accordion

.ant-collapse-content {

  .ant-collapse-content-box {
    background: @color-bg-sub-accordian-wrapper;

    .ant-collapse {
      background: transparent;
      border: none;

      &.yjSecondaryCollapsePanel {

        .site-checkbox-all-wrapper {

          ul {

            li {
              padding: 5px;

              label {
                padding-left: 25px;
                text-indent: -25px;
              }
            }

            ul {

              .ant-checkbox-wrapper {
                display: block;
                padding-left: 15px;
                text-indent: -22px;
              }
            }
          }
        }
      }

      .ant-collapse-item {
        border-bottom: none;
        margin-bottom: 0;

        .ant-collapse-header {
          background: @color-bg-sub-accordian-header;
          border: none;
          border-bottom: 1px solid @color-border;
          color: @color-font-sub-accordian-header;

          span {
            border: none;
          }

          .ant-collapse-arrow {

            svg {
              color: @color-font-sub-accordian-header-icon;
            }
          }

          .font-mixin(@font-primary, @yjff-semibold);
        }

        .ant-collapse-content {
          border: none;
          text-transform: capitalize;

          .ant-collapse-content-box {
            padding: 10px 5px 5px;
          }
        }
      }
    }
  }
}

// Secondary Accordion (Email Document and Filter Management)

.yjSecondaryAccordian {
  border: 0;
  margin-bottom: 16px;

  .ant-collapse {
    border: none;

    .ant-collapse-item {
      box-shadow: none;
      margin-bottom: 10px;

      .ant-collapse-header {
        background: @color-bg-accordian-header;
        border-radius: 0;
        color: @color-font-accordian-header;
        font-size: @font-size-base / 1.2;
        text-transform: @yj-transform;

        .ant-collapse-arrow {

          svg {
            color: @color-bg-accordian-header-arrow;
          }
        }

        .yjAccordianLabel {
          display: flex;
          overflow: hidden;
          padding-top: 3px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 90%;
        }

        .ant-checkbox-disabled+span {
          color: @color-white;
        }

        .font-mixin(@font-primary, @yjff-semibold);
      }

      .ant-collapse-extra {
        padding-top: 3px;
      }

      &.ant-collapse-no-arrow {

        .ant-collapse-header {
          cursor: default;
          padding-left: 16px;
        }
      }

      .ant-collapse-content {
        border: 1px solid @color-border;
        border-bottom: 0;
        overflow-y: auto;

        .ant-collapse-content-box {
          font-size: @font-size-base / 1.2;
        }
      }
    }
  }
}

// Alerts

.ant-alert-info {
  background-color: @color-info-alert-bg;
  border-color: @color-info-alert-border;
  color: @color-info-alert;
  margin-bottom: 5px;
  padding-left: 50px;
}

.ant-alert-success {
  background-color: @color-success-alert-bg;
  border-color: @color-success-alert-border;
  color: @color-success-alert;
  margin-bottom: 5px;
  padding-left: 50px;
}

.ant-alert-warning {
  background-color: @color-warning-alert-bg;
  border-color: @color-warning-alert-border;
  color: @color-warning-alert;
  margin-bottom: 5px;
  padding-left: 50px;
}

.ant-alert-error {
  background-color: @color-error-alert-bg;
  border-color: @color-error-alert-border;
  color: @color-error-alert;
  margin-bottom: 5px;
  padding-left: 50px;
}

// Upload - Copy Url

.ant-upload-list-item {

  .ant-upload-list-item-info {
    text-align: left;
  }
}

//Tabs

.ant-tabs-card {

  .ant-tabs-bar {
    border-bottom: 1px solid @color-border;

    .ant-tabs-tab {
      background: transparent;
      text-transform: @yj-transform;
      transition: all .6s cubic-bezier(.645, .045, .355, 1);

      &:hover {
        color: @color-primary;
      }
    }

    .ant-tabs-tab-active {
      background: transparent;
      border-bottom: 3px solid @color-secondary;
      border-color: transparent;
      color: @color-secondary;
    }
  }
}

//Pagination

.ant-table-wrapper {

  .yjGridPagination {
    float: right;
    padding: 1em 1em 0;

    .ant-pagination-item-active {
      background: @color-bg-pagination-active;
      border-color: @color-border-pagination-active;

      a {
        color: @color-font-pagination-active;
      }
    }
  }
}

//Grid Special handling

.yjGridTextWrap {
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98%;
}

.yjGridTextCenter {
  margin: 0;
  padding: 0;
  text-align: center;
}

.yjGridTextCenterDashboard {
  margin: 0;
  padding: 0;
  text-align: center;
}

.yjColorTag {
  text-transform: @yj-transform;
  width: 100px;

  .font-mixin(@font-primary, @yjff-semibold);
}

.table-row-light {
  background-color: @color-bg-table-row-light;
}

.yjTableContainer {

  /* Grid sorting background transparent issue fix */

  .ant-table {

    .ant-table-tbody {

      td {

        &.ant-table-column-sort {
          //background: inherit;
        }
      }
    }
  }

  .ant-table-content {

    table {
      table-layout: fixed;
    }
  }

  .ant-table-thead {

    .ant-checkbox-wrapper {
      margin-left: 10px;
    }

    .yjGridTextFilterSelect {

      .ant-select-selector {
        border-color: @color-bg-table-header-input-border;
      }
    }

    .ant-picker-range {
      border-color: @color-bg-table-header-input-border;
    }


    tr {

      th {
        background: #E8F0F8;
        box-shadow: none;

        &:hover {
          background: @color-bg-table-header-hover;
          transition: none;
        }

        >div {
          width: 100%;

          >span {

            &:first-child {
              width: 100%;
            }
          }
        }

        .ant-table-column-sorters {
          padding: 10px;
        }
      }

      .yjActionColumn {
        padding-top: 0;
        text-align: center;

        span {
          left: 20px;
          position: absolute;
          top: 17px;
        }
      }
    }

    .table-row-dark {
      background-color: @color-bg-table-row-dark;
    }
  }

  .ant-table-expanded-row-fixed {
    margin: 0;
    padding: 0;
  }
}

// Grid header customization

.yjMultiSelectOptionSelect {

  .ant-select-dropdown-empty {
    padding-bottom: 10px;
    text-align: center;
  }

  .ant-select-selection-item {
    background-color: fade(@color-primary, 20%);
    border: 1px dashed @color-primary;
    font-size: ceil(@font-size-base / 1.2);
    margin-right: 3px;
    padding-left: 3px;
  }

  .ant-select-selector {
    padding-right: 22px;
  }

  .ant-select-selection-search {
    max-width: 90%;

    .ant-select-selection-search-input {
      width: 90%;
    }
  }
}

.ant-select-selection-overflow-item {
  max-width: 90%;
}

.yjInfinateScrollMultiselector {

  .ant-select-selection-item {
    background: none;
    background-color: none;
    border: none;
    color: @yj-placeholder-txt;
  }

  .ant-select-dropdown {
    z-index: 1;
  }
}

.ant-table-column-has-sorters {

  .ant-table-column-sorter {
    margin-left: -11px;
  }
}

.ant-table-column-sorter-inner {
  color: @color-bg-table-header-sorter-icon;
}

.ant-table-header {

  .ant-table-cell {

    .ant-select-selector {
      border-color: @color-bg-table-header-input-border;
    }

    .ant-picker {
      border-color: @color-bg-table-header-input-border;
    }
  }
}

// Grid View Button

.yjViewButton {
  background-color: @color-bg-view-btn;
  border-color: @color-border-view-btn;
  color: @color-font-view-btn;
  font-size: @font-size-md;
  text-transform: @yj-transform;

  &:hover,
  &:active,
  &:focus {
    background: @color-bg-view-btn;
    color: @color-font-view-btn;
  }

  .font-mixin(@font-primary, @yjff-semibold);
}

//Modal popup sub heading

.yjModuleSubHeading {
  color: @color-secondary;
  font-size: @font-size-lg;
  margin-bottom: 1em;
  margin-top: 20px;
  text-transform: @yj-transform;

  .font-mixin(@font-primary, @yjff-bold);
}

.ant-modal-body {
  max-height: calc(80vh);
  overflow-y: scroll;
}

//Common Scroll Bar

&::-webkit-scrollbar {
  height: .4em;
  width: .4em;
}

&::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
}

&::-webkit-scrollbar-thumb {
  background-color: lighten(@color-accent-border, 10%);
  border-radius: 8px;
  font-size: ceil(@font-size-base / 1.2);
  height: 8px;

  &:hover {
    background-color: lighten(@color-primary, 10%);
    border-color: lighten(@color-primary, 20%);
    color: @color-white;
  }

  &:focus {
    background-color: @color-primary;
    border-color: @color-primary;
    color: @color-white;
  }
}

// Ant dropdown menu

.ant-dropdown-menu-item {
  font-size: 12px;
}

.yjMoverArrow {
  align-items: center;
  display: flex;
  justify-content: center;
}

// Delete record file - grid (file area)

.yjDeteleFile {
  background: @color-bg-remove-record-icon;
  border: none;
  box-shadow: none;
  height: 20px;
  margin-right: 10px;
  width: 20px;

  &:hover {
    background: @color-bg-remove-record-icon;
    border: none;
    box-shadow: none;
  }

  &:focus {
    background: @color-bg-remove-record-icon;
    border: none;
    box-shadow: none;
  }

  span {
    color: @color-font-remove-record-icon;
    font-size: 14px;
    vertical-align: 3px;
  }
}

//Ant tooltip

.ant-tooltip-inner {
  background-color: @color-bg-tooltip-wrapper;
  box-shadow: none;
  color: @color-font-tooltip-wrapper;
  font-size: @font-size-md;
}

.ant-tooltip-arrow-content {
  background-color: @color-bg-tooltip-wrapper;
}

// ant upload

.ant-upload-list-picture {
  margin-top: 35px;
}

.ant-upload-list {
  max-height: 100px;
  overflow: scroll;
}

//Ant tag (Filter Management)

.yjfilterTag {
  display: inline-flex;

  .ant-tag {
    margin-bottom: .5em;
  }
}

//Ant notifications

.ant-notification-notice.yjErrorMsg {
  width: 420px;
}

.yjErrorMsg {
  background: @color-bg-error-message-wrapper;
  border: 1px solid @color-bg-error-message-wrapper;

  .ant-notification-notice-message {
    color: @color-danger;
    font-size: small;
  }
}

.yjWarningMsg {
  background: @color-bg-warning-message-wrapper;
  border: 1px solid @color-border-warning-message-wrapper;

  .ant-notification-notice-message {
    color: @color-font-warning-message-wrapper;
  }
}

.yjSuccessMsg {
  background: @color-bg-success-message-wrapper;
  border: 1px solid @color-border-success-message-wrapper;

  .ant-notification-notice-message {
    color: @color-font-success-message-wrapper;
  }
}

.yjInfoMsg {
  background: @color-bg-info-message-wrapper;
  border: 1px solid @color-bg-info-message-wrapper;

  .ant-notification-notice-message {
    color: @color-font-info-message-wrapper;
  }

  .ant-notification-notice-content {

    svg {
      color: @color-font-info-message-wrapper;
    }
  }
}

// Common Tabs / Primary

.yjCommonTabs {

  .ant-tabs-card {

    .ant-tabs-nav {
      padding-bottom: 10px;

      .ant-tabs-tab {
        background: @color-bg-common-tab-inactive;
        border: none;
        border-right: 1px solid #f0f0f0;
        color: @color-font-common-tab-inactive;
        padding: 5px 10px;

        .ant-tabs-tab-btn {
          font-size: @font-size-base;
          font-weight: @yjff-bold;
          text-transform: @yj-transform;
        }

        &.ant-tabs-tab-active {

          .ant-tabs-tab-btn {
            background: @color-bg-common-tab-active;
            color: @color-font-common-tab-active;
            font-size: @font-size-base;
            font-weight: @yjff-bold;
            text-transform: @yj-transform;
          }
        }
      }
    }
  }
}

// Secondary Tabs (Portal files)

.yjSecondaryTabs {

  .ant-tabs-top {

    .ant-tabs-nav {
      margin: 0;

      .ant-tabs-nav-wrap {

        .ant-tabs-nav-list {

          .ant-tabs-tab {
            background: @color-bg-secondary-tab-inactive;
            border: 1px solid @color-border-secondary-tab-inactive;
            border-bottom: none;
            color: @color-font-secondary-tab-inactive;
            margin: 0;
            padding: 5px 40px;

            .ant-tabs-tab-btn {
              font-size: @font-size-base;
              font-weight: @yjff-bold;
              text-transform: @yj-transform;
            }

            &.ant-tabs-tab-active {
              background: @color-bg-secondary-tab-active;
              border: none;
              color: @color-font-secondary-tab-active;
              margin: 0;

              .ant-tabs-tab-btn {
                color: @color-font-secondary-tab-active;
                font-size: @font-size-base;
                font-weight: @yjff-bold;
                text-transform: @yj-transform;
              }
            }
          }
        }

        .ant-tabs-ink-bar {
          background: transparent;
        }
      }
    }
  }
}

//ant upload - Please Refactor this section

.ant-upload.ant-upload-drag {

  .ant-upload-btn {
    padding: 5px 0;
  }
}

// Common Modal

.ant-modal-root {

  .yjCommonModal {

    &Small {
      min-width: 35vw;
    }

    &Medium {
      min-width: 60vw;
    }

    &Large {
      min-width: 80vw;
    }
  }
}

// External URL

.yjExternalUrlContentForm {

  .ant-form-item {

    .ant-form-item-label {
      font-size: @font-size-lg;
      margin-bottom: 1em;
      text-transform: @yj-transform;
    }
  }
}

//--------- Dashboard UI--------------

.yjCardWrapper {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .15);
  height: 110px;
  margin-bottom: 25px;
  text-align: center;

  .ant-card-meta-description {
    color: @color-primary;
    font-size: @font-size-lg*1.05;
    font-weight: @yjff-bold;
  }
}

.yjLblStatus {

  .ant-card-meta-description {

    &:extend(.yj-font-style);

    background: @color-accent-secondary;
    border-radius: 3px;
    color: @color-white;
    font-size: @font-size-base;
    padding: 5px;
  }
}

.yjLicenedModuleCard {

  .ant-card-head {
    background: linear-gradient(180deg, @color-table-header-top-bg 0%, @color-table-header-bottom-bg 100%);
    font-size: @font-size-base*.9;
    min-height: auto;
    padding: 5px 10px;
    text-transform: @yj-transform;
  }

  .ant-card-body {
    border: 1px solid @color-border;
    padding: 15px;
  }

  .ant-card-head-title {
    padding: 0;
  }

  p {
    border-bottom: 1px solid @color-border;
    margin-bottom: 0;
    padding: 3px 0;
  }
}

.yjPieChartWrapper {
  margin-bottom: 0;

  .ant-collapse-item {

    .ant-collapse-content {
      max-height: 352px;
    }
  }
}

.yjPendingFilesCarddWrapper {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .15);
  height: 65px;
  margin-bottom: 15px;
  text-align: left;
  width: 50%;

  .ant-card-meta-detail {
    display: flex;

    .ant-card-meta-title {
      border-right: 1px solid #ccc;
      text-align: center;
      width: 50%;
    }

    .ant-card-meta-description {
      color: @color-secondary;
      margin-top: 2px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50%;
    }
  }
}

.yjDashboardTableWrapper {

  .ant-table-thead {

    tr {

      th {
        background-color: @color-white;
        color: @color-secondary;
        font-size: ceil(@font-size-base / 1.2);
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .yjDashboradTextCenter {
      text-align: center;
    }
  }

  .ant-table tbody {

    tr {

      &.ant-table-row {

        &:hover {

          td {
            background: transparent;
          }
        }
      }
    }

    .yjDashboradTextWrap {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 98%;
    }
  }
}

.yjNoWrapperButton {
  border: none;
  box-shadow: none;
  color: @color-secondary;

  &:hover {
    background: transparent;
  }
}

.yjCustomTblHeader {

  .yjTableContainer {

    .ant-table-thead {

      .ant-table-column-sorter-full {
        margin-top: -5px;
      }
    }
  }
}

.yjChannelDescription,
.yjSiteDescription {

  .ant-form-item {

    textarea.ant-input {
      max-height: 50px;
    }
  }
}

//confirmation popup in contacts model

.yjContactsConfirmationPopup {

  .ant-btn {
    font-size: 13px;
  }
}

.yjStatusActiveInactiveConfirmationPopup {

  padding: 10px;

  .ant-popover-inner {
    padding: 10px;

    .ant-popover-buttons {
      border-top: 1px solid #ccc;
      padding-top: 10px;

      .ant-btn {
        padding: 0 20px;
      }

    }
  }

}


.yjInfinityListClass .ant-list-item {
  border-bottom: none;
  display: block;
  padding: 0;
}

//Dashborad widget accordian styles

.yjDashboardWrapper {

  .yjCommonAccordian {

    .ant-collapse {

      .ant-collapse-item {

        .ant-collapse-content {
          min-height: 345px;
          overflow-x: hidden;
        }
      }
    }
  }
}

//Manage site accordian styles

.yjManageSiteContainer {

  .yjCommonAccordian {

    .ant-collapse {

      .ant-collapse-item {

        .ant-collapse-content {
          background-color: white;
          max-height: 255px;
          min-height: 150px;
          overflow-x: hidden;
        }
      }
    }
  }
}


.yjCommonModalLarge {

  .ant-modal-body {
    max-height: calc(74vh);
  }
}

@media (max-width: 1366px) {

  .yjTableContainer {

    .ant-table-thead {

      tr {

        th {
          padding: 16px 5px;

          .ant-table-column-sorters {
            padding: 0;
          }
          .ant-table-column-sorters{
            position: relative;
            .ant-table-column-sorter{
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }

        .yjActionColumn {
          padding-top: 0;
          text-align: center;

          span {
            left: 20px;
            position: absolute;
            top: 21px;
          }
        }
      }
    }
  }
}

// Fiel area specific styles - draggable column enhancement

.yjFileAreaGridWrapper {

  .ant-table-thead {

    .ant-table-column-sorter-full {
      cursor: auto;
    }
  }

  tr {

    th {

      &.yjDraggableColumn {

        &:hover {
          cursor: move;
        }
      }

    }
  }
}

.yjGenericGridColumnTitle {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.yjDraggableColumnHoverLeft {
  border-left: 3px solid @color-secondary;
}

.yjDraggableColumnHoverRight {
  border-right: 3px solid @color-secondary;
}

/*================ FOR DEMO ONLY. Please remove this code after the demo ======== */
.yjTmpPad {
  padding: 0.6em;
}

/*================ FOR DEMO ONLY. Please remove this code after the demo ======== */

.yjCreateSiteContactList {

  .ant-list-split {

    .ant-list-item {
      border-bottom: none;
      padding: 0;
    }
  }
}



.yj_error_banner {
  font-size: 18px;
  line-height: 150%;
  text-align: center;

  .yj_error_btns {
    border-radius: 5px;
    border: none;
    height: 50px;
    margin-top: 10px;
    width: 200px;

  }
}

.yjSideMenuWrapper {
  height: calc(100vh);
  overflow-y: auto;
  padding-bottom: 125px;
  display: flex;
  flex-direction: column;

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: #C8102E;
    }
  }
}

.yjModalContainerWrapper {

  .ant-form-item-label {

    label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {

      &::before {
        display: none;
      }

      &::after {
        color: #ff4d4f;
        content: '*';
        display: inline-block;
        font-size: 14px;
        line-height: 1;
        margin-right: 4px;
        margin-top: 4px;
      }
    }
  }
}

.yjSaveFilterButton {
  background: @color-bg-save-new-filter-btn;
  border-color: @color-border-save-new-filter-btn;
  color: @color-font-save-new-filter-btn;
}

.yjDropdownManageFilters {
  background: @color-accent-secondary;
  border-color: @color-accent-secondary;
  color: @color-white;
  font-size: @font-size-base;
  height: 30px;
  margin-left: 0;
  width: 100%;

  &:hover {
    background: @color-accent-secondary;
    border-color: @color-accent-secondary;
    opacity: .8;
  }
}

.pxDashboard {
  .ant-collapse-content {
    overflow: hidden;
    max-height: none !important;
  }
}

/*History Page sorting icon alignment fix*/

#fileHistory {
  .ant-table {
    .ant-table-thead {
      .ant-table-column-sorter-full {
        margin-top: 0;
      }
    }
  }
}

.ant-select-arrow .anticon > svg{
  color: #666666;
}